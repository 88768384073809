<template>
  <div :style="`font-family:${font},sans-serif`">
    <div>
      <!-- <div>
        <p>{{ this.$route.params.id }}</p>
      </div> -->

      <div class="card">
        <div class="col-md-12 col-xl-12 col-12">
          <div>
            <div class="row justify-content-between">
              <div class="col-4 mt-1" style="text-align: start">
                <!-- <b-button variant="outline-danger" @click="$router.push({ name: 'rowwasadu' })"> กลับ </b-button> -->

                <b-button variant="outline-primary" @click="exportpdf">
                  <span> Export PDF</span>
                </b-button>
              </div>
              <div class="col-4 mt-1" style="text-align: center">
                <div class="h4" style="color: #558cef">สรุปการรับเข้า-เบิกจ่าย-คงเหลือวัสดุ</div>
              </div>

              <div class="col-4 mt-1" style="text-align: end">
                <b-button variant="outline-primary" @click="exportExcel">
                  <span> Export Excel </span>
                </b-button>
                <!-- <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                  <template v-slot:button-content>
                    <b-button variant="outline-primary"
                      ><feather-icon icon="ShareIcon" class="mr-50" />Export <feather-icon icon="ChevronDownIcon" class="mr-50"
                    /></b-button>
                  </template>

                  <b-dropdown-item @click="exportExcel">
                    <feather-icon icon="FileIcon" class="mr-50" />
                    <span>Excel</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="exportpdf">
                    <feather-icon icon="FileIcon" class="mr-50" />
                    <span>PDF</span>
                  </b-dropdown-item>
                </b-dropdown> -->
              </div>
            </div>

            <hr />
            <div class="row justify-content-center">
              <div class="text-danger">จากวันที่</div>
              <span class="ml-1 mr-1">{{
                `${parseInt(this.rowData.startDate.split("-")[2])}
              ${this.month[parseInt(this.rowData.startDate.split("-")[1])]} ${
                  Number(this.rowData.startDate.split("-")[0]) + 543
                }`
              }}</span>

              <div class="ml-1 mr-1">-</div>
              <div class="text-danger">ถึงวันที่</div>
              <span class="ml-1 mr-1">{{
                `${parseInt(this.rowData.endDate.split("-")[2])}
              ${this.month[parseInt(this.rowData.endDate.split("-")[1])]} ${
                  Number(this.rowData.endDate.split("-")[0]) + 543
                }`
              }}</span>
            </div>
            <b-overlay :show="show" rounded="sm">
              <b-table :items="items" :fields="fields" responsive>
                <template #thead-top>
                  <b-tr>
                    <b-th colspan="2">
                      <span class="sr-only" />
                    </b-th>
                    <b-th variant="secondary" class="text-center" colspan="3"> ยอดยกมา </b-th>
                    <b-th variant="primary" class="text-center" colspan="3"> รับเข้า </b-th>
                    <b-th variant="danger" class="text-center" colspan="3"> เบิก </b-th>
                    <b-th variant="danger" class="text-center" colspan="3"> คงเหลือ </b-th>
                  </b-tr>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BTableSimple, BThead, BTr, BTh, BTd, BTbody, BTfoot } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import axios from "axios";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import pdfMake from "pdfmake";
import API from "@/views/connectDB/condb.js";
import pdfFonts from "../font/custom-fonts";
import _ from "lodash";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
dayjs.extend(buddhistEra);
export default {
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    VueGoodTable,
  },
  props: {
    rowData: Object,
  },
  data() {
    return {
      show: false,
      items: [],
      fields: [
        { key: "m_id", label: "หมายเลขวัสดุ" },
        { key: "m_name", label: "ชื่อวัสดุ" },

        { key: "remainAmount", label: "จำนวน" },
        { key: "unitprice_vat", label: "ราคา" },
        { key: "sum1", label: "ราคารวม" },

        { key: "amountReceive2", label: "จำนวน" },
        { key: "unitprice_vat2", label: "ราคา" },
        { key: "sum2", label: "ราคารวม" },

        { key: "drawAmount", label: "จำนวน" },
        { key: "unitprice_vat3", label: "ราคา" },
        { key: "sum3", label: "ราคารวม" },

        { key: "remainingAmount4", label: "จำนวน" },
        { key: "unitprice_vat4", label: "ราคา" },
        { key: "sum4", label: "ราคารวม" },

        // ----------------------------------------------------------------
        // { key: 'remainAmount', label: 'remainAmount' },
        // { key: 'unitprice_vat', label: 'unitprice_vat' },
        // { key: 'sum1', label: 'sum1' },

        // { key: 'amountReceive2', label: 'amountReceive2' },
        // { key: 'unitprice_vat2', label: 'unitprice_vat2' },
        // { key: 'sum2', label: 'sum2' },

        // { key: 'drawAmount', label: 'drawAmount' },
        // { key: 'unitprice_vat3', label: 'unitprice_vat3' },
        // { key: 'sum3', label: 'sum3' },

        // { key: 'remainingAmount4', label: 'remainingAmount4' },
        // { key: 'unitprice_vat4', label: 'unitprice_vat4' },
        // { key: 'sum4', label: 'sum4' },
      ],
      pageLength: 3,
      dir: false,
      month: [
        "",
        "มกราคม",
        "กุมภาพันธ์ ",
        "มีนาคม ",
        "เมษายน ",
        "พฤษภาคม ",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      searchTerm: "",
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
      font: "",
    };
  },
  computed: {
    example: {
      cache: false,
      get() {
        return Date.now() + this.msg;
      },
    },
  },
  mounted() {
    const font = localStorage.getItem("font");
    console.log(font);
    this.font = font;
    this.get_data_table();
  },
  created() {
    _.isEmpty(this.rowData) ? this.$router.push({ path: "/material" }) : true;
  },
  methods: {
    exportpdf() {
      pdfMake.vfs = pdfFonts.pdfMake.vfs; // 2. set vfs pdf font
      pdfMake.fonts = {
        // download default Roboto font from cdnjs.com
        Roboto: {
          normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
        },
        // Kanit Font
        Kanit: {
          // 3. set Kanit font
          normal: "Sarabun-Regular.ttf",
          bold: "Sarabun-Medium.ttf",
          italics: "Sarabun-Italic.ttf",
          bolditalics: "Sarabun-MediumItalic.ttf",
        },
      };
      const docDefinition = {
        pageMargins: [20, 60, 20, 80],
        pageSize: "A4",
        pageOrientation: "landscape",
        info: {
          title: "รายงานผลการตรวจสอบวัสดุเเยกตามกลุ่มวัสดุ",
        },
        header: [
          {
            text: "สถาบันทดสอบทางการศึกษาแห่งชาติ (องค์การมหาชน)",
            style: "header",
            margin: [0, 15, 0, 0],
          },
          {
            text: "สรุปรายการรับเข้า-เบิกจ่าย-คงเหลือวัสดุ",
            style: "header",
          },
          {
            text: `ตั้งเเต่วันที่ ${parseInt(this.rowData.startDate.split("-")[2])} ${
              this.month[parseInt(this.rowData.startDate.split("-")[1])]
            } ${Number(this.rowData.startDate.split("-")[0]) + 543} - ${parseInt(this.rowData.endDate.split("-")[2])} ${
              this.month[parseInt(this.rowData.endDate.split("-")[1])]
            } ${Number(this.rowData.endDate.split("-")[0]) + 543}`,
            style: "header",
          },
        ],
        content: [
          {
            style: "text",
            alignment: "center",
            table: {
              headerRows: 3,
              widths: [
                "auto",
                "*",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                // 'auto',
                // 'auto',
                // 'auto',
              ],
              body: [
                [
                  {
                    text: "หมายเลขวัสดุ",
                    style: "tableHeader",
                    rowSpan: 3,
                  },
                  {
                    text: "ชื่อวัสดุ",
                    style: "tableHeader",
                    rowSpan: 3,
                  },
                  {
                    text: "หน่วยนับ",
                    style: "tableHeader",
                    rowSpan: 3,
                  },
                  {
                    text: `จำนวนที่มีอยู่ต้นเดือน`,
                    style: "tableHeader",

                    colSpan: 3,
                  },
                  {},
                  {},
                  {
                    text: "รับเข้า",
                    style: "tableHeader",
                    colSpan: 3,
                  },
                  {},
                  {},
                  {
                    text: "เบิกของ",
                    style: "tableHeader",
                    colSpan: 3,
                  },
                  {},
                  {},
                  {
                    text: "คงเหลือ",
                    style: "tableHeader",
                    colSpan: 3,
                  },
                  {},
                  {},

                  //   text: 'คณะกรรมการตรวจนับวัสดุให้ความเห็น',
                  //   style: 'tableHeader',
                  //   colSpan: 3,
                  // },
                  // {},
                  // {},
                ],
                [
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                    // colSpan: 2,
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                    // colSpan: 2,
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },

                  {
                    text: `จำนวน`,
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                  },
                  {
                    text: `ราคา`,
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                  },
                  {
                    text: `ราคารวม`,
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                  },
                  {
                    text: "จำนวน",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                  },
                  {
                    text: "ราคา",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                  },
                  {
                    text: "ราคารวม",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                  },
                  {
                    text: "จำนวน",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                  },
                  {
                    text: "ราคา",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                  },
                  {
                    text: "ราคารวม",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                  },
                  {
                    text: "จำนวน",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                  },
                  {
                    text: "ราคา",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                  },
                  {
                    text: "ราคารวม",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                  },
                  // {
                  //   text: 'ถูกต้อง',
                  //   style: 'tableHeader',
                  //   alignment: 'center',
                  //   rowSpan: 2,
                  // },
                  // {
                  //   text: 'ขาด',
                  //   style: 'tableHeader',
                  //   alignment: 'center',
                  //   rowSpan: 2,
                  // },
                  // {
                  //   text: 'เกิน',
                  //   style: 'tableHeader',
                  //   alignment: 'center',
                  //   rowSpan: 2,
                  // },
                ],
                [
                  {
                    text: "",

                    alignment: "center",
                  },
                  {
                    text: "",

                    alignment: "center",
                  },
                  {
                    text: "",

                    alignment: "center",
                  },

                  {
                    text: ``,

                    alignment: "center",
                  },
                  {
                    text: ``,

                    alignment: "center",
                  },
                  {
                    text: ``,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  // {
                  //   text: '',
                  //   style: 'tableHeader',
                  //   alignment: 'center',
                  // },
                  // {
                  //   text: '',
                  //   style: 'tableHeader',
                  //   alignment: 'center',
                  // },
                  // {
                  //   text: '',
                  //   style: 'tableHeader',
                  //   alignment: 'center',
                  // },
                ],

                ...this?.items.map((res, index) => [
                  { text: res.m_id, style: "text_left" },
                  { text: res.m_name, style: "text_left" },
                  { text: res.unittype, style: "text_left" },
                  { text: res.remainAmount, style: "text_right" },
                  { text: res.unitprice_vat, style: "text_right" },
                  { text: res.sum1, style: "text_right" },
                  { text: res.amountReceive2, style: "text_right" },
                  { text: res.unitprice_vat2, style: "text_right" },
                  { text: res.sum2, style: "text_right" },
                  { text: res.drawAmount, style: "text_right" },
                  { text: res.unitprice_vat3, style: "text_right" },
                  { text: res.sum3, style: "text_right" },
                  { text: res.remainingAmount4, style: "text_right" },
                  { text: res.unitprice_vat4, style: "text_right" },
                  { text: res.sum4, style: "text_right" },
                  // { text: ' ', style: 'text_center' },
                  // { text: ' ', style: 'text_center' },
                  // { text: ' ', style: 'text_center' },
                ]),
              ],
            },
          },
        ],
        defaultStyle: {
          font: "Kanit",
        },
        styles: {
          tableExample: {
            margin: [0, 0, 0, 0],
          },
          tableHeader: {
            bold: true,
            fontSize: 9,
            color: "black",
            alignment: "center",
          },
          header: {
            fontSize: 9,
            bold: true,
            alignment: "center",
          },
          title: {
            bold: true,
            fontSize: 9,
            color: "black",
            margin: [0, 15, 0, 0],
          },
          anotherStyle: {
            italics: true,
            alignment: "center",
          },
          text_center: {
            alignment: "center",
            fontSize: 9,
          },
          text_left: {
            alignment: "left",
            fontSize: 9,
          },
          text_right: {
            alignment: "right",
            fontSize: 9,
          },
          text: {
            fontSize: 9,
          },
        },
      };
      pdfMake
        .createPdf(docDefinition)
        .download(`สรุปการรับเข้า-เบิกจ่าย-คงเหลือวัสดุ ${dayjs(new Date()).format("DD-MM-BBBB-HHmm")}`);
    },
    exportExcel() {
      saveExcel({
        data: this.items,
        fileName: `สรุปการรับเข้า-เบิกจ่าย-คงเหลือวัสดุ ${dayjs(new Date()).format("DD-MM-BBBB-HHmm")}`,
        columns: [
          {
            title: "สถาบันทดสอบทางการศึกษาแห่งชาติ (องค์การมหาชน)",
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: "สรุปการรับเข้า-เบิกจ่าย-คงเหลือวัสดุ",
                headerCellOptions: { textAlign: "center" },
                children: [
                  {
                    title: `จากวันที่ ${parseInt(this.rowData.startDate.split("-")[2])} ${
                      this.month[parseInt(this.rowData.startDate.split("-")[1])]
                    } ${Number(this.rowData.startDate.split("-")[0]) + 543} - ถึงวันที่ ${parseInt(
                      this.rowData.endDate.split("-")[2]
                    )} ${this.month[parseInt(this.rowData.endDate.split("-")[1])]} ${
                      Number(this.rowData.endDate.split("-")[0]) + 543
                    }`,
                    headerCellOptions: { textAlign: "center" },
                    children: [
                      {
                        title: "หมายเลขวัสดุ",
                        field: "m_id",
                      },
                      {
                        title: "ชื่อวัสดุ",
                        field: "m_name",
                      },
                      {
                        title: "หน่วยนับ",
                        field: "unittype",
                      },

                      {
                        title: "จำนวนที่มีอยู่ต้นเดือน",
                        headerCellOptions: { textAlign: "center" },
                        children: [
                          { field: "remainAmount", title: "จำนวน" },
                          { field: "unitprice_vat_ex", title: "ราคา" },
                          { field: "sum1_ex", title: "ราคารวม" },
                        ],
                      },
                      {
                        title: "รับเข้า",
                        headerCellOptions: { textAlign: "center" },
                        children: [
                          { field: "amountReceive2", title: "จำนวน" },
                          { field: "unitprice_vat2_ex", title: "ราคา" },
                          { field: "sum2_ex", title: "ราคารวม" },
                        ],
                      },
                      {
                        title: "เบิก",
                        headerCellOptions: { textAlign: "center" },
                        children: [
                          { field: "drawAmount", title: "จำนวน" },
                          { field: "unitprice_vat3_ex", title: "ราคา" },
                          { field: "sum3_ex", title: "ราคารวม" },
                        ],
                      },
                      {
                        title: "คงเหลือ",
                        headerCellOptions: { textAlign: "center" },
                        children: [
                          { field: "remainingAmount4", title: "จำนวน" },
                          { field: "unitprice_vat4_ex", title: "ราคา" },
                          { field: "sum4_ex", title: "ราคารวม" },
                        ],
                      },
                    ],
                  },
                  {},
                ],
              },
              {},
            ],
          },
          {},
        ],
      });
    },
    async get_data_table() {
      this.show = true;
      // console.log(this.rowData);
      const { access_token } = await this.access_token();
      const url = `${API}summaryMaterial?startDate=${this.rowData.startDate}&endDate=${this.rowData.endDate}&_limit=100`;
      console.log(url);
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      console.log(res.data.message.total);
      const total = res.data.message.total;
      let idx = Math.ceil(Number(total) / 100);
      let res_pdf = [];
      this.items = [];
      for (let index = 1; index <= idx; index++) {
        console.log(index);
        const url_pdf = `${API}summaryMaterial?startDate=${this.rowData.startDate}&endDate=${this.rowData.endDate}&_page=${index}&_limit=100`;
        const res_pdfs = await axios.get(url_pdf, header);
        console.log(res_pdfs);
        const { result } = res_pdfs.data.message;
        res_pdf.push(...result);
      }
      this.items = res_pdf.map((element) => ({
        ...element,
        unitprice_vat: Number(element.unitprice_vat)
          .toFixed(2)
          .toString()
          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),

        sum1: Number(element.sum1)
          .toFixed(2)
          .toString()
          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
        unitprice_vat2: Number(element.unitprice_vat2)
          .toFixed(2)
          .toString()
          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
        sum2: Number(element.sum2)
          .toFixed(2)
          .toString()
          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
        unitprice_vat3: Number(element.unitprice_vat3)
          .toFixed(2)
          .toString()
          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
        sum3: Number(element.sum3)
          .toFixed(2)
          .toString()
          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
        unitprice_vat4: Number(element.unitprice_vat4)
          .toFixed(2)
          .toString()
          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
        sum4: Number(element.sum4)
          .toFixed(2)
          .toString()
          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
        unitprice_vat_ex: Number(element.unitprice_vat),
        sum1_ex: Number(element.sum1),
        unitprice_vat2_ex: Number(element.unitprice_vat2),
        sum2_ex: Number(element.sum2),
        unitprice_vat3_ex: Number(element.unitprice_vat3),
        sum3_ex: Number(element.sum3),
        unitprice_vat4_ex: Number(element.unitprice_vat4),
        sum4_ex: Number(element.sum4),
      }));
      this.show = false;
    },
    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
  },
};
</script>

<style></style>
